import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

interface UserInfo {
  username: string;
  password: string;
}

class User {
  user_id: number;
  username: string;
  email: string;
  roles: Array<string>;
  authorization?: Array<string>;
  token?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLogInSubject = new BehaviorSubject<any>(null);
  userInfoSubject = new BehaviorSubject<any>(null);
  userInfo$ = this.userInfoSubject.asObservable();
  isLogin$ = this.isLogInSubject.asObservable();
  sharedDataSubject = new BehaviorSubject<any>(null);
  sharedData$ = this.sharedDataSubject.asObservable();

  private currentUserSubject;
  public currentUser$;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('userInfo')));
    this.currentUser$ = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getAppContent() {
    let request_url = '/assets/json/shared-content.json';
    this.http.get(request_url).subscribe(res => {
      this.sharedDataSubject.next(res);
    });
  }

  login(user: UserInfo){
    let request_body = {
      "email": user.username,
      "password": user.password
    }
    
    let request_url = 'api/master/login';

    return this.http.post(
      request_url,
      request_body
    ).pipe(tap((res: any) => {
      if (res.status === 200 && res.userInfo !== null) {
        this.isLogInSubject.next(true);
        this.userInfoSubject.next(res.userInfo);
        sessionStorage.setItem('userInfo', JSON.stringify(res.userInfo));
        this.currentUserSubject.next(res.userInfo);
      }
    }, error => {
      sessionStorage.removeItem('userInfo');
      this.isLogInSubject.next(false);
    }))
    
    if(user.username === "drasaiya@apptium.com") {
      return of({message: "Success", "userInfo": {"user_id": 1, "username": "Derris", "email": "drasaiya@apptium.com"}});
    } else {
      return throwError({"message":"User Email Not Exists"});
    }
  }

  onVerification(user: any) {
    let request_url = `api/master/login?user_id=${user.userIDControl}&otp=${parseInt(user.verificationCodeControl)}`;
    return this.http.get(request_url).pipe(tap((res: any) => {
      if (res.status === 200 && res.userInfo !== null) {
        this.isLogInSubject.next(true);
        this.userInfoSubject.next(res.userInfo);
        sessionStorage.setItem('userInfo', JSON.stringify(res.userInfo));
        this.currentUserSubject.next(res.userInfo);
      }
    }, error => {
      this.isLogInSubject.next(false);
    }));

    return of({
      "message": "Success",
      "userInfo": {
          "user_id": 1,
          "username": "Derris",
          "email": "drasaiya@apptium.com",
          "roles": ["Admin", "Developer"],
          "authorization": ["Partner Onboarding", "User Account Creation", "Custom Field Update", "PB Update"]
      },
      "status": 200,
    }).pipe(tap((res: any) => {
      if (res.status === 200 && res.userInfo !== null) {
        this.isLogInSubject.next(true);
        this.userInfoSubject.next(res.userInfo);
        sessionStorage.setItem('userInfo', JSON.stringify(res.userInfo));
        this.currentUserSubject.next(res.userInfo);
      }
    }, error => {
      sessionStorage.removeItem('userInfo');
      this.isLogInSubject.next(false);
    }))
  }

  resendVerification(userID) {
    let request_url = `api/master/resend-otp?user_id=${userID}`;
    return this.http.put( request_url, {});
  }

  isLoggedIn() {
    if(sessionStorage.getItem('userInfo') !== null) {
      return true;
    }
    return false;
  }

  logout() {
    sessionStorage.removeItem('userInfo');
    this.isLogInSubject.next(null);
    this.userInfoSubject.next(null);
    this.currentUserSubject.next(null);
    this.router.navigate(['/login'])
  }

}
