import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { VerificationComponent } from './auth/verification/verification.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'verification', component: VerificationComponent, pathMatch: 'full'},
  {
    path: 'bulk-upload',
    loadChildren: () => 
      import('./role/super-admin/components/bulk-upload/bulk-upload.module').then(
        (m) => m.BulkUploadModule
      ),
      canLoad: [AuthGuard],
  },
  {
    path: 'uploads',
    loadChildren: () =>
    import('./role/super-admin/components/uploads/uploads.module').then(
      (m) => m.UploadsModule
    ),
    canLoad: [AuthGuard],
  },
  {
    path: 'product-catalog',
    loadChildren: () =>
    import('./role/super-admin/components/catalog-management/catalog-syndication.module').then(
      (m) => m.CatalogSyndicationModule
    )
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'role', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
