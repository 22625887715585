import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ListProductsResponse } from '../bulk-upload/partner-price-book-update/product';

@Injectable({
  providedIn: 'root'
})
export class CatalogSyndicationService {
  isEditProductPage = new BehaviorSubject<any>(null);
  isEditProduct$ = this.isEditProductPage.asObservable();

  isProductFormModifiedSubject = new BehaviorSubject<any>(null);
  isProductFormModified$ = this.isProductFormModifiedSubject.asObservable();
  constructor(private http: HttpClient) { }

  downloadReport(trans_ID: string): Observable<any> {
    let request_url = `api/pc/syndication/sync_catalog/export?trans_id=cm-${trans_ID}`;
    return this.http.get(
      request_url, { responseType: 'text' }
    );
  }

  updateProductFormModified(data: boolean) {
    this.isProductFormModifiedSubject.next(data);
  }

  getAccountsList() {
    let request_url = `/assets/json/accounts-vendors-list.json`;
    return this.http.get(
      request_url
    );
  }

  getSyncFromGolden(publisherName?: string) {
    let request_url = `api/pc/syndication/get_sync_from_golden`;
    let request_body = {
      "provider_id": 30,
      "trans_type": "gc"
    }
    return this.http.post(
      request_url,
      request_body
    );
  }

  getMyProducts(publisherName?: string) {
    let request_url = `api/pc/syndication/sync_catalog/my-products`;
    return this.http.get(
      request_url
    );
  }

  

  getImportedProductsList() {
    let request_url = `/assets/json/imported-product-list.json`;
    return this.http.get(
      request_url
    );
  }

  getFetchedData() {
    // let request_url = `getpricesheets(Market=%27US%27,PricesheetView=%27updatedlicensebased%27)/$value?timeline=history&month=20240505`;
    let request_url = `api/pc/syndication/get_sync_from_golden`;
    return this.http.get(
      request_url
    );
  }

  getProductInfo(productId: string, taskId: string) {
    let request_url = `api/pc/syndication/sync_catalog/get_items?task_id=${taskId}&product_id=${productId}`;
    //let request_url = `/assets/json/list-products.json`;
    return this.http.get(
      request_url
    );
  }

  getProgramsByProductID(productId?: string) {
    let request_url = `/assets/json/get-programs.json`;
    return this.http.get(
      request_url
    );
  }

  updateProgramsByProductID(productId: string, payload: any) {
    let request_url = `/assets/json/get-programs.json`;
    return this.http.put(
      request_url,
      payload
    );
  }

  getSyncToGoldenAccountSteps() {
    return of({
      "data": [{ "state_id": 1, "name": "Review and Update" },
          { "state_id": 2, "name": "Staged Records" },
          { "state_id": 3, "name": "Publish" }],
      "status": 200
    })
  }

  getHistoryLists() {
    let request_url = `api/pc/syndication/sync_catalog_history`;
    return this.http.get(
      request_url
    );
  }

  onPublish(payload) {
    //let request_url = 'api/pc/syndication/publish_catalog';
    let request_url =`api/pc/syndication/publish-cbot-products`;
    return this.http.post(
      request_url,
      payload
    );
  }

  getProductList(taskId: string) {
    let request_url = `/assets/json/get-products-list.json`;
    let request_url_API = `/assets/json/list-products-backend.json`;
    let request_url_live = `api/pc/syndication/get_async_status/${taskId}`;
    return this.http.get(
      request_url_live
    );
  }

  getImportedProductList(paginatorObj): Observable<ListProductsResponse> {
    //let request_url = `/assets/json/get-products-list.json`;
    let request_url = `api/pc/syndication/cbot-products`;
    let params = new HttpParams()
      .set('page',paginatorObj.pageIndex.toString())
      .set('page_size', paginatorObj.pageSize.toString())
      if (paginatorObj.sort) {
        params = params.append('ordering', paginatorObj.sort);
      }
      if(paginatorObj.filterValue) {
        params = params.append('search', paginatorObj.filterValue);
      }
      if(paginatorObj.showPublishedVersion) {
        params = params.append('is_published', paginatorObj.showPublishedVersion);
      }
      if(paginatorObj.filterFormData) {
        Object.keys(paginatorObj.filterFormData).forEach(data => {
          if(paginatorObj.filterFormData[data]) params = params.append(data, paginatorObj.filterFormData[data]);
        });
        
      }
      return this.http.get<ListProductsResponse>(request_url, {params: params});
  }

  getImportedProductByID(id: number) {
    let request_url = `api/pc/syndication/cbot-products/${id}`;
    return this.http.get(request_url);
  }

  updateProduct(productPayload) {
    //let request_url = `/assets/json/get-products-list.json`;
    let request_url = `api/pc/syndication/update-cbot-products/${productPayload.product_id}`;
    return this.http.put(
      request_url,
      productPayload.data
    );
  }

  getStagedProductList(paginatorParams) {
    let params = new HttpParams()
    .set('page', paginatorParams.pageIndex.toString())
    .set('page_size', paginatorParams.pageSize.toString())
    if(paginatorParams.sort) {
      params = params.append('ordering', paginatorParams.sort)
    }
    let request_url = `api/pc/syndication/cbot-products?product_status=Staged`;
    return this.http.get(
      request_url, {params: params}
    );
  }

  unStageProducts(productPayload) {
    //let request_url = `/assets/json/get-products-list.json`;
    let request_url = `api/pc/syndication/sync_catalog/unstage`;
    return this.http.post(
      request_url,
      productPayload
    );
  }

  alterProductState(productPayload) {
    let request_url = `api/pc/syndication/sync_catalog/alter_product`;
    return this.http.put(
      request_url,
      productPayload
    );
  }

  getCloudProviders() {
    let request_url = `/assets/json/get-cloud-providers.json`;
    return this.http.get(
      request_url
    );
  }

  getProductAdditionalInfo() {
    let request_url = `/assets/json/get-product-additional-info.json`;
    return this.http.get(
      request_url
    );
  }

  uploadImage(fileData, productId ): Observable<any> {
    const formData = new FormData();
    formData.append('image_file', fileData);
    formData.append('product_id', productId);
    formData.append('user_id',JSON.parse(sessionStorage.getItem('userInfo')).user_id)
    let request_url = 'api/pc/syndication/sync_catalog/blob_upload';
    return this.http.put(request_url,formData);
  }

  getProviderList() {
    let request_url = `api/pc/syndication/get/cloudProviders`;
    return this.http.get(
      request_url
    );
  }

  addNewProvider(payload): Observable<any> {
    const formData = new FormData();
    formData.append('ion_id', payload.ion_id);
    formData.append('ion_name', payload.ion_name);
    formData.append('display_name', payload.display_name);
    formData.append('logo', payload.logo);
    formData.append('user_id',JSON.parse(sessionStorage.getItem('userInfo')).user_id);
    let request_url = 'api/pc/syndication/cloudProviders';
    return this.http.post(request_url,formData);
  }

  getSegmentList(id: number): Observable<any> {
    let request_url = `api/pc/syndication/get/segments?provider__ion_id=${id}`;
    return this.http.get(request_url);
  }

  getRegionList(): Observable<any> {
    let request_url = `api/pc/syndication/get/regions`;
    return this.http.get(request_url);
  }

  createCatalogJob(payload): Observable<any> {
    let request_url = `/api/pc/syndication/trigger/sync_job`;
    return this.http.post(
      request_url, 
      payload
    );
  }

  getCatalogJobLogs() {
    let request_url = `/assets/json/get-import-jobs-logs.json`;
    let request_url_live = `api/pc/syndication/get/sync_job_logs`;
    return this.http.get(
      request_url_live
    );
  }
  
}
