import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { CatalogSyndicationService } from 'src/app/role/super-admin/components/catalog-management/catalog-syndication.service';

@Component({
  selector: 'app-side-modal',
  templateUrl: './side-modal.component.html',
  styleUrls: ['./side-modal.component.scss']
})
export class SideModalComponent implements OnInit {

  @Input('show') show: boolean;
  @Input('planPhasesData') planPhasesData;
  @Output('close') DataOnClose: EventEmitter<any> = new EventEmitter<any>();
  constructor(private catalogService: CatalogSyndicationService, private fb: FormBuilder, private dialog: MatDialog, public rootFormGroup: FormGroupDirective) { }

  isShow: boolean;
  planPhasesForm: FormGroup;
  trialPhaseObj: TrialPhase;
  unlimitedPhaseObj: UnlimitedPhase;
  discountPhaseObj: DiscountPhase;
  fixedPhaseObj: FixedPhase;

  initialFormValue: any; 
  formChanged: boolean = false; 
  ngOnInit() {
    this.planPhasesForm = this.rootFormGroup.control.get('planPhasesForm') as FormGroup;
    if(this.planPhasesData) {
      this.trialPhaseObj = this.planPhasesData.filter(phase => phase.type === "TRIAL_PHASE")[0];
      this.unlimitedPhaseObj = this.planPhasesData.filter(phase => phase.type === "UNLIMITED_PHASE")[0];
      this.discountPhaseObj = this.planPhasesData.filter(phase => phase.type === "DISCOUNT_PHASE")[0]
      this.fixedPhaseObj = this.planPhasesData.filter(phase => phase.type === "FIXED_PHASE")[0]
    }
    this.initialFormValue = this.planPhasesForm.getRawValue();
    this.planPhasesForm.valueChanges.subscribe(() => {
      this.formChanged = !this.isFormUnchanged();
    });
  }

  private isFormUnchanged(): boolean {
    return JSON.stringify(this.initialFormValue) === JSON.stringify(this.planPhasesForm.getRawValue());
  }

  onRegularPhaseFormSlection(ev) {
    if(ev.value === "UNLIMITED_PHASE") {
      this.planPhasesForm.get('regular').get('duration').get('count').clearValidators();
    } else {
      this.planPhasesForm.get('regular').get('duration').get('count').setValidators([Validators.min(1)]);
    }
    this.planPhasesForm.get('regular').get('duration').get('count').updateValueAndValidity();
  }

  get recurringDiscountPriceFormArray() {
    return this.planPhasesForm.controls['discount'].get('recurringDiscountPriceFormArray') as FormArray;
  }

  get recurringRegularPriceFormArray() {
    return this.planPhasesForm.controls['regular'].get('recurringRegularPriceFormArray') as FormArray;
  }

  get trailPhaseType() {
    return this.planPhasesForm.get('freeTrail').get('type') as FormControl;
  }

  get discountPhaseType() {
    return this.planPhasesForm.get('discount').get('type') as FormControl;
  }

  get regularPhaseType() {
    return this.planPhasesForm.get('regular').get('type') as FormControl;
  }

  @ViewChild('exitConsentDialog', {static: true}) exitConsentDialog = {} as TemplateRef<any>;
  dialogRef;
  onClose() {
    if(this.formChanged) {
      this.dialogRef = this.dialog.open(this.exitConsentDialog, {disableClose: true, panelClass: 'discard-dialog-box'});
      this.dialogRef.afterClosed().subscribe(result => {
        if (result === "discard") {
          this.closeDialog();
        }
      })
    } else {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.DataOnClose.emit(this.planPhasesData);
  }

  onDiscardEditing() {
    this.discardChanges();
    this.dialogRef.close('discard');
  }

  updateChanges() {
    this.planPhasesData = this.formPlanPhasesData(this.planPhasesForm.getRawValue());
    this.initialFormValue = this.planPhasesForm.getRawValue();
    this.formChanged = false;
    this.catalogService.updateProductFormModified(this.formChanged);
  }

  discardChanges() {
    this.planPhasesForm.patchValue(this.initialFormValue);
    this.formChanged = false;
    this.catalogService.updateProductFormModified(this.formChanged);
  }

  showBillingPeriod(period: string): string {
    switch(period) {
      case 'MONTHLY':
        return 'month';
        break;
      case 'QUARTERLY':
        return 'quarter';
        break;
      case 'ANNUAL':
        return 'year';
        break;
      case 'TRINAL':
        return '3 years';
        break;
      default:
        return 'month';
        break;
    }
  }

  formPlanPhasesData(formData) {
    let result = [];
    Object.keys(formData).forEach(res => {
      if (formData[res].type === "skip") {
        return null;
      } else {
        let arrayData = formData[res]!.recurringRegularPriceFormArray || formData[res]!.recurringDiscountPriceFormArray;
        if (arrayData) {
          let recurringPrice = {};
          for (let priceData of arrayData) {
            recurringPrice[priceData.currency] = priceData.price;
          }
          formData[res] = {
            recurringPrice,
            ...formData[res]
          }
          delete formData[res]!.recurringRegularPriceFormArray;
          delete formData[res]!.recurringDiscountPriceFormArray;
          if (formData[res].type === "UNLIMITED_PHASE") {
            delete formData[res].duration;
          }
          result.push(formData[res]);
          return;
        } else {
          if (formData[res].type === "UNLIMITED_PHASE") {
            delete formData[res].duration;
          }
          result.push(formData[res]);
        }
      }
    });
    return result;
  }
}

export interface UnlimitedPhase {
  type: string;
  recurringPrice: RecurringPrice;
}

export interface TrialPhase {
  type: string;
  duration: Duration;
}

export interface DiscountPhase {
  type: string;
  duration: Duration;
  recurringPrice: RecurringPrice;
}

export interface FixedPhase {
  type: string;
  duration: Duration;
  recurringPrice: RecurringPrice;
}

export type Duration = {
  unit: string;
  count: number;
}

export type RecurringPrice = {
  [currencyCode: string]: number;
}
