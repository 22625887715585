import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { MatButtonModule, MatDialogModule, MatIconModule, MatListModule, MatPaginatorModule, MatProgressSpinnerModule, MatSidenavModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule,MatExpansionModule,MatFormFieldModule, MatSelectModule, MatInputModule, MatCardModule, MatRadioModule, MatSnackBarModule, MatOptionModule, MatAutocompleteModule, MatCheckboxModule, MatProgressBarModule, MatSlideToggleModule, MatDatepickerModule} from '@angular/material';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CustomersTableComponent } from './components/customers-table/customers-table.component';
import { ShellComponent } from './components/shell/shell.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { TransformTitlePipe } from './pipes/transform-title.pipe';
import { PreviewTableComponent } from './components/preview-table/preview-table.component'
import { UsersListDialogComponent } from './components/users-list-dialog/users-list-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CustomMultiSelectComponent } from './components/custom-multi-select/custom-multi-select.component';
import { RemoveDoubleUnderscore } from './pipes/remove-double-underscore.pipe';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { SideModalComponent } from './components/side-modal/side-modal.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  declarations: [HeaderComponent,SnackBarComponent, LoaderComponent, PageLoaderComponent, CustomersTableComponent, ShellComponent, ProgressBarComponent, TransformTitlePipe, PreviewTableComponent, UsersListDialogComponent, RemoveDoubleUnderscore, CustomMultiSelectComponent, SentenceCasePipe, ColorPickerComponent, SideModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatButtonModule,
    MatStepperModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatListModule,
    MatDialogModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTabsModule,
    MatExpansionModule,
    MatFormFieldModule, 
    MatSelectModule, 
    MatInputModule, 
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatListModule,
    MatRadioModule,
    MatSnackBarModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatProgressBarModule,
    NgxMatSelectSearchModule,
    MatSlideToggleModule,
    MatDatepickerModule,
  ],
  exports: [HeaderComponent, LoaderComponent, PageLoaderComponent, ShellComponent, ProgressBarComponent, TransformTitlePipe, CustomersTableComponent, CustomMultiSelectComponent, PreviewTableComponent, RemoveDoubleUnderscore, SentenceCasePipe, ColorPickerComponent, SideModalComponent],
  
  entryComponents: [SnackBarComponent]
})
export class SharedModule { }
