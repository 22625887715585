import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'removeDoubleUnderscore'
})

export class RemoveDoubleUnderscore implements PipeTransform {
  transform(value: any, ...args: any[]): any {
   let originalValue = value.split('__')[1];
   return originalValue;
  }
}