import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SuperAdminService } from 'src/app/role/super-admin/super-admin.service';

const icon_group = {
  "Partner_Details": "group",
  "Provider_Details": "business",
  "ISV_Details": "developer_board",
  "Features_Enabled": "check_circle",
  "Pricebook_Details": "attach_money",
  "Program_Details": "assignment",
  "Customer_Fetch": "find_in_page",
  "User_Validation": "verified_user",
  "Data_Validation": "check_circle",
  "Customer_Validation": "assignment_turned_in",
  "User_Creation": "person_add",
  "CustomField_Details": "input"
}

const TITLES = {
  "Partner_Details": "Partner Information",
  "Provider_Details": "Provider Information",
  "ISV_Details": "ISV Information",
  "Features_Enabled": "Enabled Features",
  "Pricebook_Details": "Pricebook Information",
  "Program_Details": "Program Information",
  "CustomField_Details": "Custom Field Information"
}

@Component({
  selector: 'app-customers-table',
  templateUrl: './customers-table.component.html',
  styleUrls: ['./customers-table.component.scss']
})
export class CustomersTableComponent implements OnInit {

  @Input() customersData : any;
  @Input() type : any;
  @Input() sharedDataContent: any;
  migratedData = [];
  validatedData;
  objectKeys = Object.keys;
  @Output() backBtn: EventEmitter<boolean> = new EventEmitter();

  constructor(private superAdminService: SuperAdminService, private dialog: MatDialog) { }

  ngOnInit() {
    this.migratedData = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.customersData) {
      this.customersData  = changes.customersData.currentValue;
      this.customersData && this.getMigratedData(this.customersData)
    }
  }

  getMigratedData(data) {
    this.customersData && Object.entries(data).map((res, i) => {
        let data = {}
        data["name"] = res[0];
        data["details"] = this.formatDetails(res[1]);
        data["isArray"] = Array.isArray(res[1]);
        data["selected"] = i === 0;
        data["icon"] = icon_group[res[0]];
        data["title"] = TITLES[res[0]];
        this.migratedData.push(data)
    })
  }

  formatDetails(data) {
    let finalDataArr = [];
    if(!Array.isArray(data)) {
      Object.entries(data).map(res => {
        let result = {}
        result["fieldName"] = res[0];
        result["fieldValue"] = res[1];
        if(res[0].includes('Remarks')) {
          if(res[1]) {
            finalDataArr.push(result);
          }
        } else {
          finalDataArr.push(result);
        }
      })
    } else {
      let remarksValue = [];
      data.map((res) => {
        Object.entries(res).map(response => {
          if(response[0].includes('Remarks')) {
            remarksValue.push(response[1]);
          } 
        })
        finalDataArr.push(res);
      });
      let filterRemarks = remarksValue.filter(res => {
        return res !== null && res !== '';
      });
      if(filterRemarks.length === 0) {
        finalDataArr.map(res => {
          Object.entries(res).map(response => {
            if(response[0].includes('Remarks')) {
              if(!response[1]) {
                delete res[response[0]];
              }
            } 
          })
        })
      }
    }
    return finalDataArr;
  }

  goback() {
    this.backBtn.emit(true);
  }

  onSelectMenu(data) {
    this.migratedData.forEach(res => {
      res['selected'] = res.name === data.name;
    });
  }
}

export interface CustomersElement {
  organisation_name: string;
  file_id: number;
  filename: string;
  result: string;
  created_at: string;
  created_by: string;
}


