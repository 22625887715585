import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'sentenceCase'
})

export class SentenceCasePipe implements PipeTransform {
    transform(value: any, ...args: any[]) {
        if (!value) return value;
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
}