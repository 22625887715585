import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarComponent } from 'src/app/shared/components/snack-bar/snack-bar.component';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  signinForm: FormGroup;
  sharedDataContent: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private snackBar: MatSnackBar) { 
    this.signinForm = this.fb.group({
      username: ['',[Validators.required, Validators.email]],
      password: ['',Validators.required]
   })
   this.authService.sharedData$.subscribe((res:any)=> {
    if (res) {
      this.sharedDataContent = res;
    }
  })
  }

  ngOnInit() {
  }

  onSignin() {
    if (!this.signinForm.invalid) {
      this.authService.login(this.signinForm.value).subscribe(response => {
        let res: any = response;
        if(res.message == 'Success') {
          this.router.navigate(['/verification'])
          sessionStorage.setItem('userInfo', JSON.stringify(res.userInfo));
          this.openSnackBar('Login OTP Sent Successfully','success');
        } 
      },
      error => {
        this.openSnackBar(error.error.message,'error');
      })
    }
  }

  openSnackBar(message: string, type: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: `snack-${type}`,
      duration: 10000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      data: { 
        message: message,
        snackType: type
      }
    })
  }

}
