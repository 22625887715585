import { ContentObserver } from '@angular/cdk/observers';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';
import { SuperAdminService } from 'src/app/role/super-admin/super-admin.service';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';

@Component({
  selector: 'app-preview-table',
  templateUrl: './preview-table.component.html',
  styleUrls: ['./preview-table.component.scss']
})
export class PreviewTableComponent implements OnInit {

  @Output() ignoreRetryBtnClick: EventEmitter<boolean> = new EventEmitter();
  @Output() isUpdatedData: EventEmitter<boolean> = new EventEmitter();
  @Output() cancelMigration: EventEmitter<boolean> = new EventEmitter();
  @Output() triggerNextStep: EventEmitter<boolean> = new EventEmitter();
  
  sharedDataContent: any;
  @Input() preview_data: any;
  @Input() migrationResponse: any;
  @Input() fileId: number;
  @Input() pageName: string;
  @Input() retryCount: number;
  fileSummary : any;
  step: any = {id : 1, name: 'Data Validation'};
  exportFileData: any
  exportFileName: string;
  headerColumns: any = [];
  subHeaderColumns: any = [];
  dataSource: any;
  tableData: any;
  tempTableData: any;
  private paginator: MatPaginator;
  private sort: MatSort;
  remarksDialogContent: any;
  dialogRef: any;
  isRetryenabled: Boolean;
  @ViewChild('viewRemarksDialog', { static: true }) viewRemarksDialogRef = {} as TemplateRef<any>;
  @ViewChild('showSaveAndCancelDialog', { static: true }) showSaveAndCancelDialogRef = {} as TemplateRef<any>;
  @ViewChild('inputValue', { static: false }) inputValueRef: ElementRef;
  @ViewChild('table', { static: true }) table: MatTable<Element>;
  @ViewChild('migrationDialogPreview', {static: true}) completeMigrationDialogOnPreview = {} as TemplateRef<any>;

  showActions: boolean = false;
  isValueIsEdited: boolean = false;
  updatedValue: any;
  updatedIndex: any;
  updatedKeys: any;
  updatedHeaderKey: any;
  updatedHeaderValue: any;
  updatedSubHeaderValue: any;
  onFocusIn: boolean;
  currentElement: any;
  onEditField: boolean = false;
  updatedPreviewData: any = [];
  previewDatatypes: any = [];
  errorState: boolean;

  @ViewChild(MatSort, { static: false }) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  constructor(
    private superAdminService: SuperAdminService,
    private authService: AuthService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe) {
    this.authService.sharedData$.subscribe((res: any) => {
      if (res) {
        this.sharedDataContent = res;
      }
    })
  }

  ngOnInit() {
    this.fileSummary = this.migrationResponse.insights;
    this.exportFileData = this.migrationResponse.exportFileData;
    this.exportFileName = this.migrationResponse.exportFileName;
    this.errorState = this.migrationResponse.migration_status === 'Failure';
    this.getHeaderColumns();
    this.getHeaderDataTypes();
    this.removeAccountSlash();
    this.dataSource = new MatTableDataSource<any>(this.preview_data);
    this.tableData = this.preview_data;
    this.tempTableData = JSON.parse(JSON.stringify(this.tableData));
    this.dataSource.filterPredicate = function (record, filter) {
      let output = [];
      Object.values(record).forEach(res => {
        Object.keys(res).forEach(field => {
          let section = res[field];
          Object.keys(section).forEach(key => {
            let val = section[key] && typeof section[key] === 'string' && section[key].toLowerCase();
            val && output.push(val.includes(filter));
          });
        });
      })
      return output.includes(true);
    }
  }

  removeAccountSlash() {
    const pattern = /^accounts\/\d+$/;
    this.preview_data.map(res => {
      return Object.values(res).map((res1: any) => {
        return Object.values(res1).map((res2: any) => {
          if(res2.hasOwnProperty('account_id') && pattern.test(res2['account_id'])) {
            res2['account_id'] =  res2['account_id'].split('/')[1];
            return res2;
          } else {
            return res2;
          }
        });
      });
    });
  }

  getHeaderDataTypes() {
    this.superAdminService.getPreviewDataType().subscribe((res : any) => {
      this.previewDatatypes = res.data;
    })
  }

  setDataSourceAttributes() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      let pagination = JSON.parse(sessionStorage.getItem('pagination'));
      if (pagination && this.paginator) {
        this.paginator.pageSize = pagination.pageSize;
        this.paginator.pageIndex = pagination.pageIndex;
      }
    }
  }

  showData(element, groupData) {
    let tableContent = ''
    let data = groupData.split('__');
    Object.entries(element).map(item => {
      tableContent = item[1][data[0]][data[1]];
      return tableContent;
    })

    return tableContent;
  }

  getHeaderColumns() {
    Object.entries(this.preview_data[0]).map((res => {
      Object.entries(res[1]).map(((group, i) => {
        if (group[0] !== 'remarks') {
          this.headerColumns.push(group[0]);
          Object.entries(group[1]).map((element => {
            this.subHeaderColumns.push(group[0] + '__' + element[0])
          }))
        }
      }))
    }))
    this.headerColumns.push('Remarks')
    this.subHeaderColumns = this.subHeaderColumns.concat(['01__Remarks']) 
  }

  getInputFieldTypes(element,subHeader) {
    let returnData = '';
    let data = subHeader.split('__');
    Object.entries(element).map(item => {
      Object.values(this.previewDatatypes).forEach((res)=> {
        Object.keys(res).forEach(field => {
          if (field === data[1]) {
            returnData = res[data[1]];
            return returnData;
          }
        })
      })
    })
    return returnData;
  }

  showRemarks(groupData) {
    let data = groupData.split('__');
    if (data[1] === 'Remarks') {
      return false;
    } else {
      return true;
    }
  }

  viewRemarks(rowData, subHeaderName, shortName) {
    if (subHeaderName.includes(shortName)) {
      Object.keys(rowData).forEach(key => {
        this.remarksDialogContent = rowData[key].remarks.remarks;
        this.openDialog();
      });
    }
  }

  updateValue(event, element, subHeaderValue) {
    this.onEditField = true;
    this.onFocusIn = true;
    let inputValue = event.target.value;
    this.updatedValue = event.target.value;
    let tableDataObj = subHeaderValue.split('__');

    let tableDataObjKeyName = tableDataObj[0];
    let tableDataObjValue = tableDataObj[1];
    this.tableData.forEach(((res, index) => {
      if (Object.keys(res)[0] === Object.keys(element)[0]) {
        if (this.tableData[index][Object.keys(res)[0]][tableDataObjKeyName][tableDataObjValue] !== inputValue) {
          document.querySelectorAll('.mat-cell.mat-column-' + subHeaderValue).forEach((element, classIndex) => {
            if (index === classIndex) {
              element.classList.add('displaySaveIcons');
            } else {
              element.classList.remove('displaySaveIcons');
            }
          })
          this.showOrHideSaveOrCancelIcons('update', index, Object.keys(res)[0], tableDataObjKeyName, tableDataObjValue, subHeaderValue);
        }
      }
    }))
  }

  saveOrCancelUpdatedValue(result, updatedValue, subHeader) {
    this.onEditField = false;
    if (result === 'save') {
      if (typeof(this.tempTableData[this.updatedIndex][this.updatedKeys][this.updatedHeaderKey][this.updatedHeaderValue]) === 'boolean') {
        if (updatedValue === 'true'){
          updatedValue = true;
        } else {
          updatedValue = false;
        }
      }
      this.tableData[this.updatedIndex][this.updatedKeys][this.updatedHeaderKey][this.updatedHeaderValue] = updatedValue;
      this.dataSource.data = this.tableData;
      this.showOrHideSaveOrCancelIcons(result, this.updatedIndex, this.updatedKeys, this.updatedHeaderKey, this.updatedHeaderValue, this.updatedSubHeaderValue)
      this.updateTableValue();
      this.updatePreviewData()
    } else if (result === 'cancel') {
      this.dataSource.data = this.tableData;
      this.showOrHideSaveOrCancelIcons(result, this.updatedIndex, this.updatedKeys, this.updatedHeaderKey, this.updatedHeaderValue, this.updatedSubHeaderValue)
      this.updateTableValue();
    }
  }

  updatePreviewData() {
    if (this.updatedPreviewData.length > 0) {
      let result = this.updatedPreviewData.filter(res => {
        return Object.keys(res)[0] === this.updatedKeys;
      })
      if (result && result.length > 0) {
        result.forEach(item => {
          if (Object.keys(item)[0] === this.updatedKeys) {
            Object.entries(Object.values(item)[0]).map(element => {
              if (element[0] === this.updatedHeaderKey) {
                this.updatedPreviewData.forEach((data,index) => {
                  if(Object.keys(data)[0] === this.updatedKeys) {
                    this.updatedPreviewData[index][this.updatedKeys][this.updatedHeaderKey] = this.tableData[this.updatedIndex][this.updatedKeys][this.updatedHeaderKey];
                  }
                })
              } else {
                this.updatedPreviewData.forEach((data,index) => {
                  if(Object.keys(data)[0] === this.updatedKeys) {
                    const obj ={}
                    obj[this.updatedHeaderKey] ={};
                    Object.assign(this.updatedPreviewData[index][this.updatedKeys],obj)   
                    this.updatedPreviewData[index][this.updatedKeys][this.updatedHeaderKey] = this.tableData[this.updatedIndex][this.updatedKeys][this.updatedHeaderKey];        
                  }
                })
              }
            })
          } else {
              let testObj = {};
              testObj[this.updatedKeys] = {};
              if(this.updatedHeaderKey !== 'partner_info') {
                testObj[this.updatedKeys]['partner_info'] = this.tableData[this.updatedIndex][this.updatedKeys]['partner_info'];
              }
              testObj[this.updatedKeys][this.updatedHeaderKey] = this.tableData[this.updatedIndex][this.updatedKeys][this.updatedHeaderKey];
              this.updatedPreviewData.push(testObj);
          }
        })
      } else {
        let testObj = {};
        testObj[this.updatedKeys] = {};
        if(this.updatedHeaderKey !== 'partner_info') {
          testObj[this.updatedKeys]['partner_info'] = this.tableData[this.updatedIndex][this.updatedKeys]['partner_info'];
        }
        testObj[this.updatedKeys][this.updatedHeaderKey] = this.tableData[this.updatedIndex][this.updatedKeys][this.updatedHeaderKey];
        this.updatedPreviewData.push(testObj);
      }
    }
    else {
      let testObj = {};
      testObj[this.updatedKeys] = {};
      if(this.updatedHeaderKey !== 'partner_info') {
        testObj[this.updatedKeys]['partner_info'] = this.tableData[this.updatedIndex][this.updatedKeys]['partner_info'];
      }
      testObj[this.updatedKeys][this.updatedHeaderKey] = this.tableData[this.updatedIndex][this.updatedKeys][this.updatedHeaderKey];
      this.updatedPreviewData.push(testObj);
    }
  }

  updateTableValue() {
    this.table.renderRows();
    this.cdRef.detectChanges();
  }

  showOrHideSaveOrCancelIcons(action, updatedIndex, updatedKeys, tableDataObjKeyName, tableDataObjValue, subHeaderValue) {
    document.querySelectorAll('.mat-cell.mat-column-' + subHeaderValue).forEach((element, classIndex) => {
      if (updatedIndex === classIndex) {
        if (action === 'save') {
          element.classList.remove('displaySaveIcons')
          this.updatedValue = '';
        } else if (action === 'cancel') {
          const inputElement = document.querySelector('.displaySaveIcons .mat-input-element') as HTMLInputElement;
          inputElement.value = this.tableData[this.updatedIndex][this.updatedKeys][this.updatedHeaderKey][this.updatedHeaderValue] === null ? '' : this.tableData[this.updatedIndex][this.updatedKeys][this.updatedHeaderKey][this.updatedHeaderValue]; 
          const event = new Event('change');
          inputElement.dispatchEvent(event);
          element.classList.remove('displaySaveIcons')
          this.updatedValue = '';
        } else if (action === 'update') {
          element.classList.add('displaySaveIcons');
          this.updatedIndex = updatedIndex;
          this.updatedSubHeaderValue = subHeaderValue;
          this.updatedKeys = updatedKeys;
          this.updatedHeaderKey = tableDataObjKeyName;
          this.updatedHeaderValue = tableDataObjValue;
        }
      } else {
        element.classList.remove('displaySaveIcons');
      }
    });
  }

  checkIsValueIsEdited() {
    if (this.updatedValue && this.onFocusIn) {
      this.onFocusIn = false;
      this.dialogRef = this.dialog.open(this.showSaveAndCancelDialogRef, { panelClass: 'complete-migration-dialog' , disableClose : true},)
      this.dialogRef.afterClosed().subscribe(result => {
        this.saveOrCancelUpdatedValue(result, this.updatedValue, this.updatedSubHeaderValue);
      })
    }
  }

  checkISValueIsDeleted(originalValue) {
    if (!this.updatedValue && this.onFocusIn) {
      if (originalValue === null && this.updatedValue.length === 0 ) {
        return;
      }
      if (originalValue === null && this.updatedValue.length){
        this.onFocusIn = false;
        this.dialogRef = this.dialog.open(this.showSaveAndCancelDialogRef, { panelClass: 'complete-migration-dialog' , disableClose : true},)
        this.dialogRef.afterClosed().subscribe(result => {
          this.saveOrCancelUpdatedValue(result, this.updatedValue, this.updatedSubHeaderValue);
        })
      } else {
        this.saveOrCancelUpdatedValue('cancel',this.updatedValue,this.updatedSubHeaderValue)
      }
    }
  }

  retryOrIgnoreDataValidation(result: string) {
    if (!(this.updatedValue && this.onFocusIn)) {
      if (result === 'ignore') {
        this.ignoreRetryBtnClick.emit(false)
      } else if (result === 'retry') {
        this.ignoreRetryBtnClick.emit(this.updatedPreviewData)
      }
    }
  }

  compareJSONObjects(object1, object2) {

    const objKeys1 = Object.keys(object1);
    const objKeys2 = Object.keys(object2);
    if (objKeys1.length !== objKeys2.length) return false;
    for (var key of objKeys1) {
      const value1 = object1[key];
      const value2 = object2[key];
      const isObjects = (value1 !== null && typeof value1 === 'object') && (value2 !== null && typeof value2 === 'object');
      if ((isObjects && !this.compareJSONObjects(value1, value2)) ||
        (!isObjects && value1 !== value2)
      ) {
        this.isUpdatedData.emit(false);
        return false;
      }
    }
    this.isUpdatedData.emit(true);
    return true;
  };

  lengthCheck(data: any) {
    let length = 0
    Object.entries(this.preview_data[0]).map((item => {
      Object.entries(item[1]).map((value => {
        if (data === value[0]) {
          length = Object.keys(value[1]).length;;
          return Object.keys(value[1]).length;
        }
      }))
    }))
    return length;
  }

  includes(name: string) {
    return name.length < 3;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  hasError(rowData, subHeaderName, shortName) {
    let result: boolean;
    if (subHeaderName.includes(shortName)) {
      Object.keys(rowData).forEach(key => {
        result = rowData[key].remarks.remarks.length;
      });
    }
    return result;
  }

  openDialog() {
    this.dialogRef = this.dialog.open(this.viewRemarksDialogRef, { panelClass: 'complete-migration-dialog' })
  }

  @HostListener('click', ['$event']) onClick($event) {
    let sameElement = this.currentElement === $event.target.id;
    if (!sameElement && this.updatedValue && ($event.target.ariaLabel === 'save' || $event.target.ariaLabel !== 'cancel')) {
      $event.preventDefault();
      this.checkIsValueIsEdited();
    }
    this.currentElement = $event.target.id
  }

    getFileNameFromContentDisposition(contentDisposition: string | null): string | null {
    if (contentDisposition) {
      const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
      if (matches && matches[1]) {
        return this.convertToLocal(matches[1].replace(/['"]/g, ''), '.csv');
      }
    }
    return null;
  }

  exportFile() {
    this.superAdminService.downloadPartnerOnboardingStepDownloadReport(this.fileId,1,0).subscribe(response => {
      const contentDispositionHeader = response.headers.get('Content-Disposition');
      const filename = this.getFileNameFromContentDisposition(contentDispositionHeader) || 'downloaded.csv';
      const blob = new Blob([response.body], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }

  convertToLocal(name: string, type: string) {
    let nameArr = name.split('_');
    let currentDate = new Date();
    let formattedDate = this.convertToYYYYMMDD(currentDate);
    let extractedTime = this.extractTimeFromDate(currentDate);
    let formattedTime = '';
    if (extractedTime) {
       let splitTime = extractedTime.split(':');
       formattedTime = splitTime[0]+splitTime[1]
    }
    nameArr.splice(-2, 2, formattedDate, formattedTime)
    return nameArr.join('_') + type;
  }

  convertToYYYYMMDD(date: Date): string {
    return this.datePipe.transform(date, 'yyyyMMdd');
  }

  extractTimeFromDate(date: Date): string {
    const formattedTime = this.datePipe.transform(date, 'HH:mm');
    return formattedTime || '';
  }

  triggerCustomerOnboardStepReportDownload() {
    const linkSource = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + this.exportFileData;
    const downloadLink = document.createElement('a'); 
    const fileName = this.convertToLocal(this.exportFileName, '.xlsx'); 
    downloadLink.href = linkSource; 
    downloadLink.download = fileName; 
    if (this.exportFileData) {
      downloadLink.click();
    } else {
      this.openSnackBar('Something went wrong!','error')
    }
  }

  openSnackBar(message: string, type: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      panelClass: `snack-${type}`,
      duration: 10000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      data: { 
        message: message,
        snackType: type
      }
    })
  }

  cancelMigrationFromPreview() {
    this.dialogRef = this.dialog.open(this.completeMigrationDialogOnPreview,{panelClass : 'complete-migration-dialog'})
  }

  cancelMigrationOnPreview() {
    this.dialogRef.close();
    this.cancelMigration.emit(true);
  }

  gotoNextStep() {
    this.triggerNextStep.emit(true);
  }

}