import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  isLoading = new Subject<boolean>();
  isProgressing = new Subject<boolean>();
  isDataLoading = new Subject<boolean>();

  constructor() { }

  show() {
    this.isLoading.next(true);
  }

  hide() {
    this.isLoading.next(false);
  }

  showProgressBar() {
    this.isProgressing.next(true);
  }

  hideProgressBar() {
    this.isProgressing.next(false);
  }

  showDataLoader() {
    this.isDataLoading.next(true);
  }

  hideDataLoader() {
    this.isDataLoading.next(false);
  }

}
