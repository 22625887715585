import { ChangeDetectorRef, Component, HostListener, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SuperAdminService } from 'src/app/role/super-admin/super-admin.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})

export class ShellComponent implements OnInit, OnDestroy {
  @ViewChild('partnerCompleteOrTerminateDialog', {static: true}) partnerCompleteOrTerminateDialog = {} as TemplateRef<any>;
  @ViewChild('exitConsentDialog', {static: true}) exitConsentDialog = {} as TemplateRef<any>;

  isLoginComponent: any;
  activePage = 'partner';
  disableSection: boolean;
  showCompleteOrTerminateToast: boolean;
  dialogRef: any;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  sideNav: MatSidenav;
  opened = false;
  position = 'start';
  isShowMenu: boolean;
  userInfo: any;
  isSettingsPage: Boolean;
  isBulkUpdate: boolean;
  isPricebook: boolean;
  isCatalogManagement: boolean;
  showDiscardDialog: boolean;
  currentRole: string[] = this.authService.currentUserValue.authorization || JSON.parse(sessionStorage.getItem('userInfo')).authorization;
  accountManagement: boolean;
  constructor(
    private router: Router, 
    private superAdminService: SuperAdminService,
    private changeDetecorRef: ChangeDetectorRef,
    private dialog: MatDialog, 
    private media: MediaMatcher,
    private authService: AuthService) {
      this.mobileQuery = media.matchMedia('(max-width: 768px)');
      this._mobileQueryListener = () => changeDetecorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
    }

  ngOnInit() {
    this.authService.isLogin$.subscribe((res: any)=>{
      if (res) {
          this.authService.userInfo$.subscribe((user)=> {
          this.userInfo = user;
        })
      } else {
        this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      }
    });
    this.superAdminService.disableSections$.subscribe(res => {
      this.disableSection = res;
    });
    this.superAdminService.showPartnerUploadStatusDialog$.subscribe((res : any) => {
      this.showCompleteOrTerminateToast = res && res.partner_upload_status;
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.selectActivePage(event.url);
      };
    });
    this.selectActivePage(this.superAdminService.currentUrl);
    this.superAdminService.showDisacardConsentDialog$.subscribe(res => {
      this.showDiscardDialog = res;
    });
  }

  selectActivePage(path: string) {
    this.isSettingsPage = path.includes('/settings');
    this.isBulkUpdate = false;
    this.isPricebook = false;
    this.accountManagement = false;
    switch(path) {
      case '/uploads/partner-upload':
        this.activePage = 'partner';
        this.accountManagement = true;
        break;
      case '/uploads/user-upload':
        this.activePage = 'customer';
        this.accountManagement = true;
        break;
      case '/uploads/audit-details':
        this.activePage = 'migration';
        this.accountManagement = true;
        break;
      case '/uploads/migration-history':
        this.activePage = 'migration';
        this.accountManagement = true;
        break;
      case '/bulk-upload/price-book':
        this.activePage = 'priceBook';
        this.isBulkUpdate = true;
        break;
      case '/bulk-upload/price-book/partner-account':
        this.activePage = 'PriceBookpartner';
        this.isBulkUpdate = true;
        this.isPricebook = true;
        break;
      case '/bulk-upload/customer-price-book':
        this.activePage = 'customer-priceBook';
        this.isBulkUpdate = true;
        this.isPricebook = true;
        break;
      case '/bulk-upload/custom-fields':
        this.activePage = 'customFields';
        this.accountManagement = true;
        break;
      case '/bulk-upload/update-history':
        this.activePage = 'updateHistory';
        this.isBulkUpdate = true;
        break;
      case '/product-catalog/sync-catalog-account':
        this.activePage = 'syncAccount';
        this.isCatalogManagement = true;
        break;
      case '/product-catalog/sync-history':
        this.activePage = 'syncLog';
        this.isCatalogManagement = true;
        break;
      case '/product-catalog/product-inventory':
        this.activePage = 'productInventory';
        this.isCatalogManagement = true;
      break;
      case '/product-catalog/import-products':
        this.activePage = 'catalogImports';
        this.isCatalogManagement = true;
      break;
      default:
        if(path.includes('/uploads/audit-details')) {
          this.activePage = 'migration';
        } else if(path.includes('/product-catalog/product-inventory')) {
          this.activePage = 'productInventory';
          this.isCatalogManagement = true;
        } else if(path.includes('/product-catalog/sync-products-stepper')) {
          this.activePage = 'syncCatalog';
          this.isCatalogManagement = true;
        } else if(path.includes('/product-catalog/import-products') || path.includes('/product-catalog/import-jobs') || path.includes('/product-catalog/imported-products')) {
          this.activePage = 'catalogImports';
          this.isCatalogManagement = true;
        } else {
          this.activePage = 'partner';
        }
        break;
    }
  }

  goToPage(type) {
    if(this.disableSection) {
      if (this.showCompleteOrTerminateToast) {
        this.openPartnerCompleteOrTerminateDialog();
      }
    } else if(this.showDiscardDialog) {
      this.openExitConsentDialog(type);
    } else {
      sessionStorage.removeItem('pagination');
      sessionStorage.removeItem('UserPagination');
      this.activePage = type;
      if (type === 'partner') {
        this.router.navigate(['uploads/partner-upload'])
      } else if (type === 'customer') {
        this.router.navigate(['uploads/user-upload'])
      } else if (type === 'audit') {
        this.router.navigate(['uploads/audit-details'])
      } else if (type === 'migration') {
        this.router.navigate(['uploads/migration-history'])
      } else if (type === 'customFields') {
        this.router.navigate(['bulk-upload/custom-fields'])
      } else if (type === 'partner-priceBook') {
        this.router.navigate(['bulk-upload/price-book/partner-account'])
      } else if (type === 'customer-priceBook') {
        this.router.navigate(['bulk-upload/customer-price-book'])
      } else if (type === 'updateHistory') {
        this.router.navigate(['bulk-upload/update-history'])
      } else if (type === 'syncAccount') {
        this.router.navigate(['product-catalog/sync-catalog-account'])
      } else if (type === 'syncLog') {
        this.router.navigate(['product-catalog/sync-history'])
      } else if (type === 'productInventory') {
        this.router.navigate(['product-catalog/product-inventory'])
      } else if (type === 'catalogImports') {
        this.router.navigate(['product-catalog/import-products'])
      }
      this.opened = false;
      this.position = 'end';
    }
  }

  showHideMenu() {
    this.opened = !this.opened;
  }

  openPartnerCompleteOrTerminateDialog() {
    this.dialogRef = this.dialog.open(this.partnerCompleteOrTerminateDialog,{panelClass : 'complete-termination-dialog'})
  }

  logout() {
    this.activePage = 'logout';
    this.opened = false;
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  openExitConsentDialog(type) {
    this.dialogRef = this.dialog.open(this.exitConsentDialog, {disableClose: true, autoFocus: false, restoreFocus: false, panelClass : 'exit-consent-price-book'});
    this.dialogRef.afterClosed().subscribe(result => {
      if(result === 'discard') {
        this.showDiscardDialog = false;
        this.superAdminService.updateDisacardConsentDialog(this.showDiscardDialog);
        if(type !== 'header') {
          this.goToPage(type);
        }
      }
    });
  }

  onDiscard() {
    this.dialogRef.close('discard');
  }

  isAuthorizedUser(...params) {
    let result = params.find(role => {
      return this.currentRole.indexOf(role) !== -1;
    })
    return Boolean(result);
  }
}
