import { Component , OnInit} from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { LoaderService } from './shared/services/loader.service';

export let browserRefresh = false;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(private authService: AuthService, private loaderService: LoaderService, private router: Router) {
    this.router.events.subscribe((e : RouterEvent) => {
      this.navigationInterceptor(e);
    })
  }

  ngOnInit() {
    this.authService.getAppContent();
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
        this.loaderService.show();
        browserRefresh = !this.router.navigated;
        if (browserRefresh && event.url === '/verification') {
          this.router.navigate['login'];
          sessionStorage.removeItem('userInfo');
        }
    }
    if (event instanceof NavigationEnd) {
      setTimeout(()=>{                           
        this.loaderService.hide();
    }, 2000);
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(()=>{                           
        this.loaderService.show();
    }, 2000);
    }
    if (event instanceof NavigationError) {
      setTimeout(()=>{                           
        this.loaderService.show();
      }, 2000);
    }
  }
}
