import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBarRef: MatSnackBarRef<SnackBarComponent>,
  ) { }

  ngOnInit() {
  }

  get icon() {
    switch (this.data.snackType) {
      case 'success': 
        return 'check_circle';
      case 'error':
        return 'info';
      case 'warning':
        return 'warning';
    }
    return true;
  }

  closeSnackBar() {
    this.snackBarRef.dismiss();
  }

}
