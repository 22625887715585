import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const routeData = route.data;
    const currentRole: string[] = this.authService.currentUserValue.authorization || JSON.parse(sessionStorage.getItem('userInfo')).authorization;
    let authorizedRole: string[] = routeData.role;
    if(this.authService.isLoggedIn() && this.isAuthorizedUser(currentRole, authorizedRole)) {
      return true;
    } else if(this.authService.isLoggedIn() && currentRole.indexOf("User Account Creation") !== -1) {
      this.router.navigate(['/uploads/user-upload'])
    } else if(this.authService.isLoggedIn() && currentRole.indexOf("Custom Field Update") !== -1) {
      this.router.navigate(['/bulk-upload/custom-fields'])
    } else if(this.authService.isLoggedIn() && currentRole.indexOf("PB Update") !== -1) {
      this.router.navigate(['/bulk-upload/price-book/partner-account'])
    }
    return false;
  }

  canLoad(route: Route): boolean {
    if(this.authService.isLoggedIn()) {
      return true;
    }
    this.router.navigate(['/login'])
    return false;
  }

  isAuthorizedUser(userRole: string[], authorizedRole: string[]): boolean {
    let result = authorizedRole.find(role => {
      return userRole.indexOf(role) !== -1;
    })
    return Boolean(result);
  }
  
}