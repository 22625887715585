import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {

  constructor(private http: HttpClient) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          if(!error.url.includes('exception-log') && error.error && !error.error.audited) {
            let request_url = 'api/master/exception-log';
            let request_body = {
              url: error.url,
              payload: request.body,
              exception_type: error.status,
              exception_message: error.error.message ? error.error.message : error.message,
              exception_source: error.error.message ? 'Server' : 'Client',
              api_type: 'Internal'
            }
            this.http.post(request_url,request_body).subscribe();
          }
          const errorObj = {};
          if(error.error && error.error.audited) {
            errorObj['message'] = "Something went wrong";
            return throwError({error: errorObj});
          }
          return throwError(error);
        })
      )
  }
}