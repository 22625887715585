import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-users-list-dialog',
  templateUrl: './users-list-dialog.component.html',
  styleUrls: ['./users-list-dialog.component.scss']
})
export class UsersListDialogComponent implements OnInit {

  userData : any;
  sharedDataContent: any;
  constructor(public dialogRef: MatDialogRef<UsersListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService) { 
      this.authService.sharedData$.subscribe((res:any)=> {
        if (res) {
          this.sharedDataContent = res;
        }
      })
    }

  ngOnInit() {
    this.userData = this.data;
  }

}

export interface DialogData {
  userName: string;
  userEmail: string;
  status: string;
}
