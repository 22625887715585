import { Pipe, PipeTransform } from '@angular/core';
const abbrev = ['ISV', 'ID', 'MPNID', 'PAC'];

@Pipe({
  name: 'transformTitle'
})

export class TransformTitlePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if(value !== 'User_Creation') {
      if(!value.includes(' ')) {
        let arg = args[0];
        let result = this.isCamelCase(value).split("_").map(res => {
          if (abbrev.includes(res.toUpperCase())) {
            return res.toUpperCase();
          }
          return res[0].toUpperCase() + res.slice(1).toLowerCase();
        })
        if(arg === 'first') {
          return result[0];
        }
        return result.join(' ');
      } else {
        return value;
      }
    } else {
      return 'User Account Creation'
    }
  }

  isCamelCase(word: string) {
    if(!word.includes('_')) {
      const regex = /(?=[A-Z][a-z])/;
      return word.split(regex).join("_");
    }
    return word;
  }
}